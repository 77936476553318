"use strict";

var isMobile = function isMobile() {
  return window.outerWidth < 1200 ? true : false;
};

var stickyHeader = function stickyHeader(selector, className) {
  document.addEventListener("scroll", function (e) {
    window.pageYOffset > 0 ? selector.classList.add(className) : selector.classList.remove(className);
  });
};
/*
 * Header JS
 */


var headerFn = function headerFn() {
  var body = document.querySelector('body');
  var header = document.querySelector('[data-header]');
  var searchButton = document.querySelector('[data-search-button]');
  var searchOverlay = document.querySelector('[data-search-overlay]');
  var menuButton = document.querySelector('[data-menu-button]');
  var menuOverlay = document.querySelector('[data-menu-overlay]');
  var menuCloseButton = document.querySelector('[data-menu-close-button]');
  var navSub = document.querySelectorAll("[data-nav-sub]");
  var navSubContent = document.querySelectorAll("[data-nav-sub-content]");
  var activeContentId = null;
  var activeContentControl = true;

  var headerHover = function headerHover() {
    navSubContent.forEach(function (content) {
      if (content.classList.contains("-active")) {
        activeContentId = content.getAttribute("data-nav-sub-content");
      }
    });
    header.addEventListener('mouseover', function (e) {
      header.classList.add("-menu");
    });
    header.addEventListener('mouseout', function (e) {
      header.classList.remove("-menu");
    });
  };

  var searchForm = function searchForm() {
    if (!search) return;
    var search = document.querySelector("[data-search-input]");
    var button = document.querySelector("[data-search-button]");
    search.addEventListener("keyup", function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        button.click();
      }
    });
  };

  var searchButtonOverlay = function searchButtonOverlay() {
    if (!searchButton) return;
    searchButton.addEventListener("click", function () {
      if (searchOverlay.classList.contains("overlay-search-open")) {
        searchOverlay.classList.remove("overlay-search-open");
        header.classList.remove("-search");
        body.classList.remove("no-scroll");
      } else {
        searchOverlay.classList.add("overlay-search-open");
        header.classList.add("-search");
        setTimeout(function () {
          body.classList.add("no-scroll");
        }, 300);
        menuOverlay.classList.remove("overlay-menu-open");
        header.classList.remove("-mobile-menu");
      }
    });
  };

  var mobileMenuButton = function mobileMenuButton() {
    if (!menuButton) return;
    menuButton.addEventListener("click", function () {
      if (menuOverlay.classList.contains("overlay-menu-open")) {
        menuOverlay.classList.remove("overlay-menu-open");
        header.classList.remove("-mobile-menu");
      } else {
        menuOverlay.classList.add("overlay-menu-open");
        header.classList.add("-mobile-menu");
        searchOverlay.classList.remove("overlay-search-open");
        header.classList.remove("-search");
        body.classList.remove("no-scroll");
      }
    });
  };

  var mobileMenuButtonClose = function mobileMenuButtonClose() {
    if (!menuCloseButton) return;
    menuCloseButton.addEventListener("click", function () {
      return menuOverlay.classList.remove("overlay-menu-open");
    });
  };

  var navSubHover = function navSubHover() {
    navSub.forEach(function (nav) {
      nav.addEventListener("mouseover", function () {
        var id = nav.getAttribute("data-nav-sub");
        navSub.forEach(function (nav) {
          return nav.classList.remove("-hover");
        });
        navSubContent.forEach(function (content) {
          return content.classList.remove("-active");
        });
        nav.classList.add("-hover");
        var item = document.querySelector("[data-nav-sub-content='".concat(id, "']"));
        if (item) item.classList.add("-active");
      });
    });
  };

  var overlayBackButton = function overlayBackButton() {
    var button = document.querySelectorAll("[data-overlay-sub-back]");
    var content = document.querySelectorAll("[data-overlay-sub-content]");
    button.forEach(function (el) {
      el.addEventListener("click", function () {
        content.forEach(function (el) {
          return el.classList.remove("overlay-menu__sub--open");
        });
      });
    });
  };

  var overlayMenu = function overlayMenu() {
    var sub = document.querySelectorAll("[data-overlay-sub]");
    var content = document.querySelectorAll("[data-overlay-sub-content]");
    sub.forEach(function (el) {
      el.addEventListener("click", function (e) {
        var id = e.target.getAttribute("data-overlay-sub");
        content.forEach(function (el) {
          return el.classList.remove("overlay-menu__sub--open");
        });
        var item = document.querySelector("[data-overlay-sub-content=\"".concat(id, "\"]"));
        if (item) item.classList.add("overlay-menu__sub--open");
      });
    });
  };

  var overlaySubMenu = function overlaySubMenu() {
    var sub = document.querySelectorAll("[data-overlay-nav-sub]");
    var content = document.querySelectorAll("[data-overlay-nav-sub-content]");
    sub.forEach(function (el) {
      el.addEventListener("click", function () {
        var id = el.getAttribute("data-overlay-nav-sub");
        var item = document.querySelector("[data-overlay-nav-sub-content=\"".concat(id, "\"]"));

        if (item) {
          if (item.classList.contains("overlay-menu__sub__list--open")) {
            content.forEach(function (item) {
              item.classList.remove("overlay-menu__sub__list--open");
              item.style.height = 0;
            });
          } else {
            content.forEach(function (item) {
              item.classList.remove("overlay-menu__sub__list--open");
              item.style.height = 0;
            });
            item.classList.add("overlay-menu__sub__list--open");
            var height = "".concat(item.querySelector(".overlay-menu__sub__group").offsetHeight, "px");
            item.style.height = height;
          }
        }
      });
    });
  };

  var init = function init() {
    searchForm();
    headerHover();
    searchButtonOverlay();
    mobileMenuButton();
    mobileMenuButtonClose();
    navSubHover();
    overlayMenu();
    overlaySubMenu();
    overlayBackButton();
    stickyHeader(header, "-sticky");
  };

  init();
};

var pageSelectFn = function pageSelectFn() {
  var select = document.querySelector("[data-page-select]");

  if (select) {
    select.addEventListener('change', function (e) {
      window.location.href = e.target.value;
    });
  }
};

var productSlider = function productSlider() {
  var element = document.querySelector("[data-project-slider]");

  if (element) {
    var process = new Swiper('[data-project-slider]', {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      centeredSlides: true,
      paginationClickable: true,
      loop: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: false,
      breakpoints: {
        1024: {
          slidesPerView: 'auto'
        }
      }
    });
  }
};
/*
 * Privacy Policy JS
 */


var cookiePolicy = function cookiePolicy(newOptions) {
  var options = {
    cookieName: 'Cookie Policy',
    cookieValue: true,
    selector: null,
    button: null,
    className: null,
    date: 365 * 24 * 60 * 60 * 1000
  };

  var setOptions = function setOptions(defaultOptions, newOptions) {
    for (var item in defaultOptions) {
      if (newOptions[item] === undefined) newOptions[item] = defaultOptions[item];
    }

    return newOptions;
  };

  var getCookie = function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  };

  var setCookie = function setCookie(cname, cvalue) {
    var date = new Date();
    date.setTime(date.getTime() + options.date);
    var expires = 'expires=' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  var addCookie = function addCookie() {
    setCookie(options.cookieName, options.cookieValue);
    options.selector.classList.remove(options.className);
    options.button.removeEventListener('click', addCookie);
  };

  var isCookie = function isCookie() {
    var cookie = getCookie(options.cookieName);
    return !!cookie != options.cookieValue;
  };

  var cookieControl = function cookieControl() {
    if (isCookie()) {
      options.button.addEventListener('click', addCookie);
      options.selector.classList.add(options.className);
    } else {
      if (options.selector.classList.contains(options.className)) options.selector.classList.remove(options.className);
    }
  };

  var init = function init() {
    options = setOptions(options, newOptions);
    if (!options.selector || !options.button) return;
    cookieControl();
  };

  init();
};
/*
 * Init
 */


document.addEventListener('DOMContentLoaded', function () {
  headerFn();
  pageSelectFn();
  productSlider();
  cookiePolicy({
    selector: document.querySelector('[data-policy]'),
    button: document.querySelector('[data-policy-close]'),
    className: '-show'
  });
});
if ('AOS' in window) AOS.init();